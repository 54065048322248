<template>
    <div v-if="!$isMobile">
        <a-card :bordered="false" v-if="!hiddenFilter">
            <div class="filter-container">
                <p>筛选部门/员工：</p>
                <a-space class="site-input-group-wrapper" direction="vertical" size="middle">
                    <a-input-group compact>
                        <a-select style="width :100px" v-model:value="departmentOrEmployee" placeholder="请选择"
                            @change="getTypeData">
                            <a-select-option value="按部门">按部门</a-select-option>
                            <a-select-option value="按员工">按员工</a-select-option>
                        </a-select>
                        <a-select :filter-option="filterSouOption" show-search style="width :150px"
                            v-model:value="departmentOrEmployeeId" placeholder="请选择" @change="getData">
                            <a-select-option v-for="(item, index) in departmentOrEmployeeList" :value="item.id"
                                :key="item.id" :text="item.name">{{
                                    item.name
                                }}</a-select-option>
                        </a-select>
                    </a-input-group>
                </a-space>
            </div>
        </a-card>
        <a-card class="card-container" title="核心数据" :bordered="false">
            <template #extra>
                <a-range-picker class="page-btm" style="width: 230px; background: #F3F4F8;" v-model:value="coreDataTime"
                    :placeholder="['开始时间', '结束时间']" @change="getData" />
            </template>
            <div v-if="!coreMsg">
                <a-row :gutter="10">
                    <a-col :span="8">
                        <card :CurrentData="signedAmount.CurrentData" :LastData="signedAmount.LastData" :bg="'#EFF7FF'"
                            prefix="￥" suffix="元" title="签单金额(元)" tips="根据签单时间统计"></card>
                    </a-col>
                    <a-col :span="8">
                        <card :CurrentData="receiptAmount.CurrentData" :LastData="receiptAmount.LastData" :bg="'#FFF4F5'"
                            prefix="￥" suffix="元" title="回款金额(元)" tips="根据回款时间统计"></card>
                    </a-col>
                    <a-col :span="8">
                        <card :CurrentData="numberOfOrders.CurrentData" :LastData="numberOfOrders.LastData" :bg="'#F0FAFF'"
                            suffix="单" title="签单数(单)" tips="根据签单时间统计"></card>
                    </a-col>
                </a-row>
                <a-row style="margin-top: 10px;" :gutter="10">
                    <!-- <a-col :span="profileName !== '藤德留学' ? 8 : 6">
                        <card :CurrentData="businessOpportunityCustomer.CurrentData" :bg="'#F5F4F9'"
                            :LastData="businessOpportunityCustomer.LastData" title="商机客资数" tips="根据商机创建时间统计"></card>
                    </a-col>
                    <a-col :span="profileName !== '藤德留学' ? 8 : 6">
                        <card :CurrentData="numberOfOpportunities.CurrentData" :LastData="numberOfOpportunities.LastData"
                            :bg="'#F5F4F9'" title="商机数" tips="根据商机创建时间统计"></card>
                    </a-col> -->
                    <a-col :span="8">
                        <card :CurrentData="numberOfOpportunities.CurrentData" :LastData="numberOfOpportunities.LastData"
                            :bg="'#F5F4F9'" title="接收客资数" tips="根据客资创建时间统计"></card>
                    </a-col>
                    <a-col :span="8" style="cursor: pointer;" @click="toLink('/customer/allocationCustomer?select=highIntentions')">
                        <card :CurrentData="highIntentCustomerCapital.CurrentData" :bg="'#F5F4F9'"
                            :LastData="highIntentCustomerCapital.LastData" title="高意向客资数" tips="根据客资录入时间统计"></card>
                    </a-col>
                    <a-col :span="8" class="download_con" style="cursor: pointer;" @click="toLink('/customer/allocationCustomer?select=precision')">
                        <DownloadOutlined @click="getDownload" class="download-icon" />
                        <card :CurrentData="preciseCustomer.CurrentData" :LastData="preciseCustomer.LastData" title="精准客资数"
                            :bg="'#F5F4F9'" tips="根据客户标签设置为精准客户的时间统计"></card>
                    </a-col>
                </a-row>
            </div>
            <div class="no-data" :style="{ width: '100%', height: '300px', textAlign: 'center', paddingTop: '150px' }"
                v-else> {{
                    coreMsg }}</div>
        </a-card>
        <a-row :gutter="10">
            <a-col :span="10" class="card-h">
                <a-card :bordered="false">
                    <template #title>
                        <div class="flex-col">
                            <span style="margin-bottom: 6px;">签单转化率</span>
                            <div style="margin-bottom: 6px;">
                                <a-range-picker class="page-btm" style="width: 220px;background: #F3F4F8;"
                                    v-model:value="funnelDataTime" :placeholder="['开始时间', '结束时间']"
                                    @change="getFunnelChart" />
                            </div>
                        </div>
                    </template>
                    <div id="funnelChart" :style="{ width: '100%', height: '400px' }" v-if="!funnelChartMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '400px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            funnelChartMsg }}</div>
                </a-card>
            </a-col>
            <!-- <a-col :span="6" class="card-h">
                <a-card :bordered="false">
                    <template #title>
                        <div class="flex-col">
                            <span style="margin-bottom: 6px;">商机客资来源</span>
                            <div style="margin-bottom: 6px;">
                                <DownloadOutlined @click="download('商机客资来源')" style="margin-right: 10px;" />
                                <a-range-picker class="page-btm" style="width: 220px;background: #F3F4F8;"
                                    v-model:value="pieDataTime" :placeholder="['开始时间', '结束时间']" @change="getPieChart" />
                            </div>
                        </div>
                    </template>
                    <div id="pieChart" :style="{ width: '100%', height: '400px' }" v-if="!pieChartMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '400px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            pieChartMsg }}</div>
                </a-card>
            </a-col> -->
            <a-col :span="14">
                <a-card :bordered="false" title="趋势图">
                    <template #extra>
                        <a-range-picker class="page-btm" style="width: 230px;background: #F3F4F8;"
                            v-model:value="lineDataTime" :placeholder="['开始时间', '结束时间']" @change="getLineData" />
                    </template>
                    <div id="lineChart" :style="{ width: '100%', height: '400px' }" v-if="!lineMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '400px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            lineMsg }}</div>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="10" style="margin-top: 10px;">
            <a-col v-if="profileName === '藤德留学'" :span="12">
                <a-card :bordered="false" title="公司排名">
                    <template #extra>
                        <DownloadOutlined @click="download('公司排名')" style="margin-right: 10px;" />
                        <a-range-picker class="page-btm" style="width: 230px;background: #F3F4F8;"
                            v-model:value="companyDataTime" :placeholder="['开始时间', '结束时间']" @change="getCompanyData" />
                    </template>
                    <div id="companyChart" :style="{ width: '100%', height: '300px' }" v-if="!companyMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '300px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            companyMsg }}</div>
                </a-card>
            </a-col>
            <a-col :span="12">
                <a-card :bordered="false" title="部门排名">
                    <template #extra>
                        <DownloadOutlined @click="download('部门排名')" style="margin-right: 10px;" />
                        <a-range-picker class="page-btm" style="width: 230px;background: #F3F4F8;"
                            v-model:value="departmentDataTime" :placeholder="['开始时间', '结束时间']"
                            @change="getDepartmentData" />

                    </template>
                    <div id="departmentChart" :style="{ width: '100%', height: '300px' }" v-if="!departmentMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '300px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            departmentMsg }}</div>
                </a-card>
            </a-col>
        </a-row>
    </div>
    <div class="m-data-glance" v-else>
        <a-card :bordered="false" v-if="!hiddenFilter">
            <div class="filter-container">
                <p style="font-size:12px">筛选部门/员工：</p>
                <a-space class="site-input-group-wrapper" direction="vertical" size="middle">
                    <a-input-group compact>
                        <a-select style="width :90px" v-model:value="departmentOrEmployee" placeholder="请选择"
                            @change="getTypeData">
                            <a-select-option value="按部门">按部门</a-select-option>
                            <a-select-option value="按员工">按员工</a-select-option>
                        </a-select>
                        <a-select :filter-option="filterSouOption" show-search style="width :100px"
                            v-model:value="departmentOrEmployeeId" placeholder="请选择" @change="getData">
                            <a-select-option v-for="(item, index) in departmentOrEmployeeList" :value="item.id"
                                :key="item.id" :text="item.name">{{
                                    item.name
                                }}</a-select-option>
                        </a-select>
                    </a-input-group>
                </a-space>
            </div>
        </a-card>
        <a-card class="card-container" title="核心数据" :bordered="false">
            <template #extra>
                <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''" class="page-btm"
                    style="width: 220px; background: #F3F4F8;" v-model:value="coreDataTime" :placeholder="['开始时间', '结束时间']"
                    @change="getData" />
            </template>
            <div v-if="!coreMsg">
                <a-row :gutter="10">
                    <a-col :span="24">
                        <card :CurrentData="signedAmount.CurrentData" :LastData="signedAmount.LastData" :bg="'#EFF7FF'"
                            prefix="￥" suffix="元" title="签单金额(元)" tips="根据签单时间统计"></card>
                    </a-col>
                </a-row>
                <a-row :gutter="10" class="card-container">
                    <a-col :span="24">
                        <card :CurrentData="receiptAmount.CurrentData" :LastData="receiptAmount.LastData" :bg="'#FFF4F5'"
                            prefix="￥" suffix="元" title="回款金额(元)" tips="根据回款时间统计"></card>
                    </a-col>
                </a-row>
                <a-row :gutter="10">
                    <a-col :span="24">
                        <card :CurrentData="numberOfOrders.CurrentData" :LastData="numberOfOrders.LastData" :bg="'#F0FAFF'"
                            suffix="单" title="签单数(单)" tips="根据签单时间统计"></card>
                    </a-col>
                </a-row>
                <!-- <a-row style="margin-top: 10px;" :gutter="10">
                    <a-col :span="24">
                        <card :CurrentData="businessOpportunityCustomer.CurrentData" :bg="'#F5F4F9'"
                            :LastData="businessOpportunityCustomer.LastData" title="商机客资数" tips="根据商机创建时间统计"></card>
                    </a-col>
                </a-row>
                <a-row style="margin-top: 10px;" :gutter="10">
                    <a-col :span="24">
                        <card :CurrentData="numberOfOpportunities.CurrentData" :LastData="numberOfOpportunities.LastData"
                            :bg="'#F5F4F9'" title="商机数" tips="根据商机创建时间统计"></card>
                    </a-col>
                </a-row> -->
                <a-row style="margin-top: 10px;" :gutter="10">
                    <a-col :span="24">
                        <card :CurrentData="numberOfOpportunities.CurrentData" :LastData="numberOfOpportunities.LastData"
                            :bg="'#F5F4F9'" title="接收客资数" tips="根据客资创建时间统计"></card>
                    </a-col>
                </a-row>
                <a-row style="margin-top: 10px;" :gutter="10">
                    <a-col :span="24" @click="toLink('/customer/allocationCustomer?select=highIntentions')">
                        <card :CurrentData="highIntentCustomerCapital.CurrentData" :bg="'#F5F4F9'"
                            :LastData="highIntentCustomerCapital.LastData" title="高意向客资数" tips="根据客资录入时间统计"></card>
                    </a-col>
                </a-row>
                <a-row style="margin-top: 10px;" :gutter="10">
                    <a-col :span="24" class="download_con" @click="toLink('/customer/allocationCustomer?select=precision')">
                        <DownloadOutlined @click="getDownload" class="m-download-icon" />
                        <card :CurrentData="preciseCustomer.CurrentData" :LastData="preciseCustomer.LastData" title="精准客资数"
                            :bg="'#F5F4F9'" tips="根据客户标签设置为精准客户的时间统计"></card>
                    </a-col>
                </a-row>
            </div>
            <div class="no-data" :style="{ width: '100%', height: '300px', textAlign: 'center', paddingTop: '150px' }"
                v-else> {{
                    coreMsg }}</div>
        </a-card>
        <a-row :gutter="10">
            <a-col :span="24">
                <a-card :bordered="false" title="签单转化率">
                    <template #extra>
                        <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                            class="page-btm" style="width: 190px; background: #F3F4F8;" v-model:value="funnelDataTime"
                            :placeholder="['开始时间', '结束时间']" @change="getFunnelChart" />
                    </template>
                    <div id="funnelChart" :style="{ width: '100%', height: '400px' }" v-if="!funnelChartMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '400px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            funnelChartMsg }}</div>
                </a-card>
            </a-col>
        </a-row>
        <!-- <a-row :gutter="10" class="card-container">
            <a-col :span="24">
                <a-card :bordered="false" title="商机客资来源">
                    <template #extra>
                        <DownloadOutlined @click="download('商机客资来源')" style="margin-right: 10px;" />
                        <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                            class="page-btm" style="width: 160px; background: #F3F4F8;" v-model:value="pieDataTime"
                            :placeholder="['开始时间', '结束时间']" @change="getPieChart" />
                    </template>
                    <div id="pieChart" :style="{ width: '100%', height: '400px' }" v-if="!pieChartMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '400px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            pieChartMsg }}</div>
                </a-card>
            </a-col>
        </a-row> -->
        <a-row :gutter="10">
            <a-col :span="24">
                <a-card :bordered="false" title="趋势图">
                    <template #extra>
                        <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                            class="page-btm" style="width: 230px; background: #F3F4F8;" v-model:value="lineDataTime"
                            :placeholder="['开始时间', '结束时间']" @change="getLineData" />
                    </template>
                    <div id="lineChart" :style="{ width: '100%', height: '400px' }" v-if="!lineMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '400px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            lineMsg }}</div>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="10" style="margin-top: 10px;">
            <a-col v-if="profileName === '藤德留学'" :span="24">
                <a-card :bordered="false" title="公司排名">
                    <template #extra>
                        <DownloadOutlined @click="download('公司排名')" style="margin-right: 10px;" />
                        <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                            class="page-btm" style="width: 190px; background: #F3F4F8;" v-model:value="companyDataTime"
                            :placeholder="['开始时间', '结束时间']" @change="getCompanyData" />
                    </template>
                    <div id="companyChart" :style="{ width: '100%', height: '300px' }" v-if="!companyMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '300px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            companyMsg }}</div>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="10" style="margin-top: 10px;">
            <a-col :span="24">
                <a-card :bordered="false" title="部门排名">
                    <template #extra>
                        <DownloadOutlined @click="download('部门排名')" style="margin-right: 10px;" />
                        <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                            class="page-btm" style="width: 190px; background: #F3F4F8;" v-model:value="departmentDataTime"
                            :placeholder="['开始时间', '结束时间']" @change="getDepartmentData" />

                    </template>
                    <div id="departmentChart" :style="{ width: '100%', height: '300px' }" v-if="!departmentMsg"></div>
                    <div class="no-data"
                        :style="{ width: '100%', height: '300px', textAlign: 'center', paddingTop: '150px' }" v-else> {{
                            departmentMsg }}</div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import dayjs from "dayjs";
import { getProfile } from "../../../utils/session";
import card from "@/components/home/card";
import { markRaw } from 'vue';
export default {
    name: 'SaasCrmDataGlanceDetail',
    components: {
        card
    },
    data() {
        return {
            coreMsg: null,
            funnelChartMsg: null,
            pieChartMsg: null,
            lineMsg: null,
            companyMsg: null,
            departmentMsg: null,
            permissions: null,
            profileName: '',
            pieChartVo: null,
            rankings: null,
            departRankings: null,
            hiddenFilter: false,
            departmentOrEmployee: '按部门',
            departmentOrEmployeeId: null,
            departmentOrEmployeeList: [],
            departmentIdIs: '',
            idIn: '',
            coreDataTime: [],
            funnelChart: null,
            pieChart: null,
            lineChart: null,
            companyChart: null,
            departmentChart: null,
            signedAmount: {},
            receiptAmount: {},
            numberOfOrders: {},
            businessOpportunityCustomer: {},
            numberOfOpportunities: {},
            highIntentCustomerCapital: {},
            preciseCustomer: {},
            funnelDataTime: [],
            pieDataTime: [],
            lineDataTime: [],
            companyDataTime: [],
            departmentDataTime: [],

            lineParameters: 'SIGNED_AMOUNT',
            companyParameters: 'SIGNED_AMOUNT',
            departmentParameters: 'SIGNED_AMOUNT',

            lineData: [],
            companyData: [],
            departmentData: [],

            lineOption: {
                legend: {
                    show: true,
                    selectedMode: 'single',
                    selected: { // 设置默认不显示
                        '签单金额': true,
                        '回款金额': false,
                        '精准客资数': false,
                        // '商机数': false
                    },
                    bottom: '2%',
                    right: '6%'
                },
                grid: {
                    left: 100,
                    right: '10%',
                    top: '3%',
                    bottom: '20%',
                },
                tooltip: {
                    trigger: 'item',
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value',
                    minInterval: 1,
                    splitNumber: 3,
                },
                color: ['#F56D73', '#6566EA', '#FCA16B', '#60CEAB'],
                series: [
                    {
                        name: '签单金额',
                        type: 'line',
                        data: [],
                        smooth: true,
                        symbol: 'circle',
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#F56D73',
                                    },
                                    {
                                        offset: 1,
                                        color: '#ffffff',
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    {
                        name: '回款金额',
                        type: 'line',
                        data: [],
                        smooth: true,
                        symbol: 'circle',
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#6566EA',
                                    },
                                    {
                                        offset: 1,
                                        color: '#ffffff',
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    {
                        name: '精准客资数',
                        type: 'line',
                        data: [],
                        smooth: true,
                        symbol: 'circle',
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: '#FCA16B',
                                    },
                                    {
                                        offset: 1,
                                        color: '#ffffff',
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                    // {
                    //     name: '商机数',
                    //     type: 'line',
                    //     data: [],
                    //     smooth: true,
                    //     symbol: 'circle',
                    //     areaStyle: {
                    //         color: {
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [
                    //                 {
                    //                     offset: 0,
                    //                     color: '#60CEAB',
                    //                 },
                    //                 {
                    //                     offset: 1,
                    //                     color: '#ffffff',
                    //                 },
                    //             ],
                    //             global: false,
                    //         },
                    //     },
                    // },
                ]

            },

            companyOption: {
                legend: {
                    show: true,
                    selectedMode: 'single',
                    selected: { // 设置默认不显示
                        '签单金额': true,
                        '回款金额': false,
                        '精准客资数': false,
                        // '商机数': false
                    },
                    bottom: '2%',
                    right: '6%'
                },
                grid: {
                    left: 100,
                    right: '10%',
                    top: '3%',
                    bottom: '26%',
                },
                tooltip: {
                    trigger: 'item',
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    minInterval: 1,
                    splitNumber: 3
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    offset: 0,
                    axisLabel: {
                        rotate: this.$isMobile ? 60 : 0
                    }
                },
                color: ['#F56D73', '#6566EA', '#FCA16B', '#60CEAB'],
                series: [
                    {
                        name: '签单金额',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    },
                    {
                        name: '回款金额',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    },
                    {
                        name: '精准客资数',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    },
                    // {
                    //     name: '商机数',
                    //     type: 'bar',
                    //     data: [],
                    //     label: {
                    //         show: true,
                    //         position: 'right',
                    //         valueAnimation: true
                    //     }
                    // },
                ]
            },

            departmentOption: {
                legend: {
                    show: true,
                    selectedMode: 'single',
                    selected: { // 设置默认不显示
                        '签单金额': true,
                        '回款金额': false,
                        '精准客资数': false,
                        // '商机数': false
                    },
                    bottom: '2%',
                    right: '6%'
                },
                grid: {
                    left: 100,
                    right: '10%',
                    top: '3%',
                    bottom: '26%',
                },
                tooltip: {
                    trigger: 'item',
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    minInterval: 1,
                    splitNumber: 3
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisTick: { show: false },
                    axisLabel: {
                        rotate: this.$isMobile ? 60 : 0
                    }
                },
                color: ['#F56D73', '#6566EA', '#FCA16B', '#60CEAB'],
                series: [
                    {
                        name: '签单金额',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    },
                    {
                        name: '回款金额',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    },
                    {
                        name: '精准客资数',
                        type: 'bar',
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            valueAnimation: true
                        }
                    },
                    // {
                    //     name: '商机数',
                    //     type: 'bar',
                    //     data: [],
                    //     label: {
                    //         show: true,
                    //         position: 'right',
                    //         valueAnimation: true
                    //     }
                    // },
                ]
            },
        }
    },
    onUnmounted() {
        this.funnelChart.dispose();
    },
    mounted() {
        this.getProfile();
        this.getWeek();
        this.getDepartment();
        this.getFunnelChart();
        this.getCoreData();

        // this.getPieChart();

        this.getLineData();
        this.getDepartmentData();
    },
    methods: {
        handleUrlAction(action, rows) {
            const urlStr = action.url.replace(/{(\w+)}/g, (substr, group) => rows[0][group]);
            const url = new URL(/^https?:\/\//.test(urlStr) ? urlStr : window.location.origin + urlStr);
            if (action.params === true) {
                if (this.departmentOrEmployee == '按部门') {
                    this.departmentIdIs = this.departmentOrEmployeeId
                    this.idIn = ''
                } else {
                    this.idIn = this.departmentOrEmployeeId
                    this.departmentIdIs = ''
                }
                let { departmentIdIs, idIn } = this
                let coreDataDateOnOrAfter = this.coreDataTime[0].format('YYYY-MM-DD 00:00:00');
                let coreDataDateOnOrBefore = this.coreDataTime[1].format('YYYY-MM-DD 23:59:59')
                const listForm = { coreDataDateOnOrAfter, coreDataDateOnOrBefore, departmentIdIs, idIn };
                Object.keys(listForm).forEach(key => {
                    const value = listForm[key];
                    if (value != null && value.length !== 0) {
                        url.searchParams.append(key, listForm[key]);
                    }
                });
            }
            if (action.newPage) {
                window.open(url.toString());
            } else {
                window.location.href = url.toString();
            }
        },
        async getDownload() {
            let action = { url: '/api/dataReport/dataOverview/accurateCustomerCapitalExport', params: true, newPage: true }
            this.handleUrlAction(action)
        },
        noDataChart(dom) {
            dom.removeChild(dom.firstChild)
            const mainText = document.createElement('h3')
            dom.appendChild(mainText)
            dom.style.display = 'flex'
            dom.style.flexDirection = 'column'
            dom.style.justifyContent = 'center'
            dom.style.alignItems = 'center'
            mainText.innerHTML = '暂无数据'
            mainText.style.color = '#999999'
            dom.removeAttribute('_echarts_instance_')
        },
        filterSouOption(input, option) {
            return option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },
        getTypeData(val) {
            this.departmentOrEmployeeId = null
            if (val == '按部门') {
                this.getDepartment()
                // this.getPieChart();
                this.getLineData();
                this.getCoreData();
            } else {
                this.getEmployee()
            }
        },
        async getProfile() {
            try {
                let profile = await getProfile();
                this.profileName = profile.institutions.name;
                this.permissions = profile.everyPermissions;
                if (this.profileName == '藤德留学') {
                    this.getCompanyData();
                }
            } catch ({ message, status }) {
                if (status !== 401) {
                    this.$message.error(message);
                }
            }
        },
        getCategoryName(name) {
            let categoryName;
            switch (name) {
                case "SIGNED_AMOUNT":
                    categoryName = '签单金额';
                    break;
                case "REFUND_AMOUNT":
                    categoryName = '回款金额';
                    break;
                case "BUSINESS_OPPORTUNITY_CUSTOMER_CAPITAL":
                    categoryName = '精准客资数';
                    break;
                // case "NUMBER_OF_OPPORTUNITIES":
                //     categoryName = '商机数';
                //     break;
            }
            return categoryName;
        },
        download(val) {
            require.ensure([], () => {
                const { export_json_to_excel } = require("@/utils/Export2Excel");
                let tableHeader, filterVal, dataList;
                // if (val == '商机客资来源') {
                //     if (!this.permissions('/dataReport/dataOverview/pieChart')) {
                //         this.$message.error("没有权限导出");
                //         return;
                //     }
                //     tableHeader = ["来源渠道", "商机客资数"];
                //     filterVal = ["name", "value"];
                //     dataList = this.pieChartVo
                // } 
                if (val == '公司排名') {
                    if (!this.permissions('/dataReport/dataOverview/companyRankings')) {
                        this.$message.error("没有权限导出");
                        return;
                    }
                    let categoryName = this.getCategoryName(this.companyParameters)
                    tableHeader = ["公司", categoryName];
                    filterVal = ["company", "amount"];
                    dataList = this.rankings
                } else if (val == '部门排名') {
                    if (!this.permissions('/dataReport/dataOverview/sectorRankings')) {
                        this.$message.error("没有权限导出");
                        return;
                    }
                    let categoryName = this.getCategoryName(this.departmentParameters)
                    tableHeader = ["部门", categoryName];
                    filterVal = ["department", "amount"];
                    dataList = this.departRankings
                } else { }
                let list = JSON.parse(JSON.stringify(dataList));
                let data = this.formatJson(filterVal, list);
                export_json_to_excel(tableHeader, data, val);
            });
        },
        formatJson(filterVal, Data) {
            return Data.map(v => filterVal.map(j => v[j]));
        },
        //失去焦点获取数据
        getData() {
            this.getCoreData();
            // this.getPieChart();
            this.getLineData();
        },
        //获取员工数据
        async getEmployee() {
            try {
                let res = await this.$http.get('/dataReport/dataOverview/employeeAll');
                if (res.code == '200') {
                    this.departmentOrEmployeeList = res.list
                }
            } catch ({ message }) {

            }
        },
        //获取部门数据
        async getDepartment() {
            try {
                let res = await this.$http.get('/dataReport/dataOverview/list/get');
                if (res.code == '200') {
                    this.departmentOrEmployeeList = res.departmentList
                } else if (res.code == '201') {
                    this.hiddenFilter = true
                }
            } catch ({ message }) {

            }
        },
        //默认一周数据
        getWeek() {
            const currentDate = dayjs();
            const firstDayOfWeek = currentDate.format('YYYY-MM' + '-01 00:00:00');
            const lastDayOfWeek = currentDate.format('YYYY-MM-DD' + ' 23:59:59');
            let week = []
            week[0] = dayjs(firstDayOfWeek)
            week[1] = dayjs(lastDayOfWeek)
            this.coreDataTime = week;
            this.pieDataTime = week;
            this.lineDataTime = week;
            this.companyDataTime = week;
            this.departmentDataTime = week;
            this.funnelDataTime = week;
        },
        // 核心数据
        async getCoreData() {
            if (this.departmentOrEmployee == '按部门') {
                this.departmentIdIs = this.departmentOrEmployeeId
                this.idIn = ''
            } else {
                this.idIn = this.departmentOrEmployeeId
                this.departmentIdIs = ''
            }
            let { departmentIdIs, idIn } = this
            let coreDataDateOnOrAfter = this.coreDataTime[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.coreDataTime[1].format('YYYY-MM-DD 23:59:59')
            try {
                let res = await this.$http.get('/dataReport/dataOverview/list', { coreDataDateOnOrAfter, coreDataDateOnOrBefore, departmentIdIs, idIn });
                if (res.code == '200') {
                    //签单金额
                    this.signedAmount = {
                        CurrentData: res.amountVo.currentSignedAmount,
                        LastData: res.amountVo.lastSignedAmount,
                    }
                    //回款金额
                    this.receiptAmount = {
                        CurrentData: res.amountVo.currentReceiptAmount,
                        LastData: res.amountVo.lastReceiptAmount,
                    }
                    //签单数
                    this.numberOfOrders = {
                        CurrentData: res.amountVo.currentNumberOfOrders,
                        LastData: res.amountVo.lastNumberOfOrders,
                    }
                    // //商机客资数
                    // this.businessOpportunityCustomer = {
                    //     CurrentData: res.amountVo.currentBusinessOpportunityCustomer,
                    //     LastData: res.amountVo.lastBusinessOpportunityCustomer,
                    // }
                    // //商机数
                    // this.numberOfOpportunities = {
                    //     CurrentData: res.amountVo.currentNumberOfOpportunities,
                    //     LastData: res.amountVo.lastNumberOfOpportunities,
                    // }
                    //接受客资数
                    this.numberOfOpportunities = {
                        CurrentData: res.amountVo.currentNumberOfCustomers,
                        LastData: res.amountVo.lastNumberOfCustomers,
                    }
                    //高意向客资数
                    this.highIntentCustomerCapital = {
                        CurrentData: res.amountVo.currentHighIntentCustomerCapital,
                        LastData: res.amountVo.lastHighIntentCustomerCapital,
                    }
                    //精准客资数
                    this.preciseCustomer = {
                        CurrentData: res.amountVo.currentPreciseCustomer,
                        LastData: res.amountVo.lastPreciseCustomer,
                    }

                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.coreMsg = '暂无权限'
                }
            }
        },


        async getFunnelChart() {
            let { departmentIdIs, idIn } = this;
            let coreDataDateOnOrAfter = this.funnelDataTime[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.funnelDataTime[1].format('YYYY-MM-DD 23:59:59');
            try {
                let res = await this.$http.get('/dataReport/dataOverview/funnelChart', { coreDataDateOnOrAfter, coreDataDateOnOrBefore, departmentIdIs, idIn });
                if (res.code == '200') {
                    this.initFunnel(res.funnelVo);
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.funnelChartMsg = '暂无权限'
                }
            }
        },

        //签单转化率echarts
        initFunnel(data) {
            let arr = [
                { value: data.numberOfEntries, name: '接收客资', percent: data.numberOfEntries != 0 ? 100 : 0 },
                { value: data.numberOfOpportunities, name: '精准客资', percent: data.numberOfEntries != 0 ? (data.numberOfOpportunities / data.numberOfEntries * 100).toFixed(2) : 0 },
                { value: data.numberOfSignedOrders, name: '签单客资', percent: data.numberOfOpportunities != 0 ? (data.numberOfSignedOrders / data.numberOfOpportunities * 100).toFixed(2) : 0 },
                { value: data.recoverAmount, name: '回款客资', percent: data.numberOfSignedOrders != 0 ? (data.recoverAmount / data.numberOfSignedOrders * 100).toFixed(2) : 0 },
            ];
            let totalPercent = data.numberOfEntries != 0 ? ((data.recoverAmount / data.numberOfEntries) * 100).toFixed(2) : 0;

            const domMap = document.getElementById("funnelChart");
            domMap.removeAttribute("_echarts_instance_");

            this.funnelChart = this.$echarts.init(domMap);
            let option = {
                title: {
                    text: '总转化率：' + totalPercent + '%',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (e) {
                        return e.data.name + ': ' + e.data.value + '<br/>转化率: ' + e.data.percent + '%';
                    }
                },
                series: [
                    {
                        name: '签单转化率',
                        type: 'funnel',
                        sort: 'none',
                        left: '0%',
                        top: 50,
                        bottom: 20,
                        width: '100%',
                        minSize: '0%',
                        maxSize: '100%',
                        gap: 2,
                        color: ['#F56D73', '#6566EA', '#FCA16B', '#60CEAB'],
                        label: {
                            show: true,
                            position: 'inside',
                            color: '#ffffff',
                            formatter: function (e) {
                                return e.data.name + ': ' + e.data.value + '\n\n转化率: ' + e.data.percent + '%';
                            }
                        },
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1
                        },
                        emphasis: {
                            label: {
                                fontSize: 20
                            }
                        },
                        data: arr
                    }
                ]
            };
            this.funnelChart.setOption(option, true);
        },

        // async getPieChart() {
        //     if (this.departmentOrEmployee == '按部门') {
        //         this.departmentIdIs = this.departmentOrEmployeeId
        //         this.idIn = ''
        //     } else {
        //         this.idIn = this.departmentOrEmployeeId
        //         this.departmentIdIs = ''
        //     }
        //     let { departmentIdIs, idIn } = this;
        //     let coreDataDateOnOrAfter = this.pieDataTime[0].format('YYYY-MM-DD 00:00:00');
        //     let coreDataDateOnOrBefore = this.pieDataTime[1].format('YYYY-MM-DD 23:59:59');
        //     try {
        //         let res = await this.$http.get('/dataReport/dataOverview/pieChart', { coreDataDateOnOrAfter, coreDataDateOnOrBefore, departmentIdIs, idIn });
        //         if (res.code == '200') {
        //             this.pieChartVo = res.pieChartVo;
        //             this.initPie(res.pieChartVo);
        //         }
        //     } catch ({ message }) {
        //         if (message == '没有权限访问') {
        //             this.pieChartMsg = '暂无权限'
        //         }
        //     }
        // },

        // 商机客资来源echarts
        // initPie(data) {
        //     const domMap = document.getElementById("pieChart");
        //     domMap.removeAttribute("_echarts_instance_");

        //     this.pieChart = this.$echarts.init(domMap);

        //     let option = {
        //         tooltip: {
        //             trigger: 'item',
        //             formatter: '{b} <br/>数量: {c} <br/>占比 : {d}%'
        //         },
        //         series: [
        //             {
        //                 name: 'Access From',
        //                 type: 'pie',
        //                 radius: '80%',
        //                 width: '100%',
        //                 top: 20,
        //                 color: ['#F56D73', '#6566EA', '#FCA16B', '#60CEAB'],
        //                 label: {
        //                     show: true,
        //                     position: 'inside',
        //                     color: '#ffffff',
        //                     formatter: '{b}({d}%)'
        //                 },
        //                 data: data,
        //                 emphasis: {
        //                     itemStyle: {
        //                         shadowBlur: 10,
        //                         shadowOffsetX: 0,
        //                         shadowColor: 'rgba(0, 0, 0, 0.5)'
        //                     }
        //                 }
        //             }
        //         ]
        //     };
        //     this.pieChart.setOption(option, true);
        // },

        async getLineData() {
            if (this.departmentOrEmployee == '按部门') {
                this.departmentIdIs = this.departmentOrEmployeeId
                this.idIn = ''
            } else {
                this.idIn = this.departmentOrEmployeeId
                this.departmentIdIs = ''
            }
            let { departmentIdIs, idIn } = this;
            let coreDataDateOnOrAfter = this.lineDataTime[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.lineDataTime[1].format('YYYY-MM-DD 23:59:59');
            let trendParameters = this.lineParameters;
            try {
                let res = await this.$http.get('/dataReport/dataOverview/trendChart', {
                    coreDataDateOnOrAfter,
                    coreDataDateOnOrBefore,
                    departmentIdIs,
                    idIn,
                    trendParameters
                });
                if (res.code == '200') {
                    this.iniLine(res.trendChartVos)
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.lineMsg = '暂无权限'
                }
            }
        },

        // 趋势图echarts
        iniLine(data) {
            let dateData = [];
            let amountData = [];
            data.map((item) => {
                dateData.push(item.time);
                amountData.push(item.amount);
            })
            const domMap = document.getElementById("lineChart");
            domMap.removeAttribute("_echarts_instance_");
            this.lineChart = markRaw(this.$echarts.init(domMap));

            this.lineOption.xAxis.data = dateData;
            switch (this.lineParameters) {
                case "SIGNED_AMOUNT":
                    this.lineOption.series[0].data = amountData;
                    break;
                case "REFUND_AMOUNT":
                    this.lineOption.series[1].data = amountData;
                    break;
                case "BUSINESS_OPPORTUNITY_CUSTOMER_CAPITAL":
                    this.lineOption.series[2].data = amountData;
                    break;
                // case "NUMBER_OF_OPPORTUNITIES":
                //     this.lineOption.series[3].data = amountData;
                //     break;
            }
            this.lineChart.setOption(this.lineOption, true);
            this.lineChart.on("legendselectchanged", (e) => {
                if (e.name == '签单金额') {
                    this.lineOption.legend.selected = {
                        '签单金额': true,
                        '回款金额': false,
                        '精准客资数': false,
                        // '商机数': false
                    };
                } else if (e.name == '回款金额') {
                    this.lineOption.legend.selected = {
                        '签单金额': false,
                        '回款金额': true,
                        '精准客资数': false,
                        // '商机数': false
                    };
                } else if (e.name == '精准客资数') {
                    this.lineOption.legend.selected = {
                        '签单金额': false,
                        '回款金额': false,
                        '精准客资数': true,
                        // '商机数': false
                    };
                }
                //  else if (e.name == '商机数') {
                //     this.lineOption.legend.selected = {
                //         '签单金额': false,
                //         '回款金额': false,
                //         '精准客资数': false,
                //         '商机数': true
                //     };

                // }
                this.lineParameters = this.getEnumeration(e.name);
                this.getLineData();
            })


        },

        async getCompanyData() {
            let coreDataDateOnOrAfter = this.companyDataTime[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.companyDataTime[1].format('YYYY-MM-DD 23:59:59');
            let trendParameters = this.companyParameters;
            try {
                let res = await this.$http.get('/dataReport/dataOverview/companyRankings', {
                    coreDataDateOnOrAfter,
                    coreDataDateOnOrBefore,
                    trendParameters
                });
                if (res.code == '200') {
                    this.rankings = res.rankings
                    this.initCompany(res.rankings)
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.companyMsg = '暂无权限'
                }
            }
        },

        //公司排名echarts
        initCompany(data) {
            let dateData = [];
            let amountData = [];
            data.map((item) => {
                dateData.push(item.company);
                amountData.push(item.amount);
            });
            const domMap = document.getElementById("companyChart");
            domMap.removeAttribute("_echarts_instance_");
            this.companyChart = markRaw(this.$echarts.init(domMap));

            this.companyOption.yAxis.data = dateData;
            switch (this.companyParameters) {
                case "SIGNED_AMOUNT":
                    this.companyOption.series[0].data = amountData;
                    break;
                case "REFUND_AMOUNT":
                    this.companyOption.series[1].data = amountData;
                    break;
                case "BUSINESS_OPPORTUNITY_CUSTOMER_CAPITAL":
                    this.companyOption.series[2].data = amountData;
                    break;
                // case "NUMBER_OF_OPPORTUNITIES":
                //     this.companyOption.series[3].data = amountData;
                //     break;
            }
            this.companyChart.setOption(this.companyOption, true);
            this.companyChart.on("legendselectchanged", (e) => {
                if (e.name == '签单金额') {
                    this.companyOption.legend.selected = {
                        '签单金额': true,
                        '回款金额': false,
                        '精准客资数': false,
                        // '商机数': false
                    };
                } else if (e.name == '回款金额') {
                    this.companyOption.legend.selected = {
                        '签单金额': false,
                        '回款金额': true,
                        '精准客资数': false,
                        // '商机数': false
                    };
                } else if (e.name == '精准客资数') {
                    this.companyOption.legend.selected = {
                        '签单金额': false,
                        '回款金额': false,
                        '精准客资数': true,
                        // '商机数': false
                    };
                }
                // else if (e.name == '商机数') {
                //     this.companyOption.legend.selected = {
                //         '签单金额': false,
                //         '回款金额': false,
                //         '精准客资数': false,
                //         '商机数': true
                //     };
                // }
                this.companyParameters = this.getEnumeration(e.name);
                this.getCompanyData();
            })


        },

        async getDepartmentData() {
            let coreDataDateOnOrAfter = this.departmentDataTime[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.departmentDataTime[1].format('YYYY-MM-DD 23:59:59');
            let trendParameters = this.departmentParameters;
            try {
                let res = await this.$http.get('/dataReport/dataOverview/sectorRankings', {
                    coreDataDateOnOrAfter,
                    coreDataDateOnOrBefore,
                    trendParameters
                });
                if (res.code == '200') {
                    this.initDepartment(res.rankings)
                    this.departRankings = res.rankings
                }
            } catch ({ message }) {
                if (message == '没有权限访问') {
                    this.departmentMsg = '暂无权限'
                }
            }
        },

        //部门排名echarts
        initDepartment(data) {
            let dateData = [];
            let amountData = [];
            data.map((item) => {
                dateData.push(item.department);
                amountData.push(item.amount);
            });
            const domMap = document.getElementById("departmentChart");
            domMap.removeAttribute("_echarts_instance_");
            this.departmentChart = markRaw(this.$echarts.init(domMap));
            this.departmentOption.yAxis.data = dateData;
            switch (this.departmentParameters) {
                case "SIGNED_AMOUNT":
                    this.departmentOption.series[0].data = amountData;
                    break;
                case "REFUND_AMOUNT":
                    this.departmentOption.series[1].data = amountData;
                    break;
                case "BUSINESS_OPPORTUNITY_CUSTOMER_CAPITAL":
                    this.departmentOption.series[2].data = amountData;
                    break;
                // case "NUMBER_OF_OPPORTUNITIES":
                //     this.departmentOption.series[3].data = amountData;
                //     break;
            }
            this.departmentChart.setOption(this.departmentOption, true);
            this.departmentChart.on("legendselectchanged", (e) => {
                if (e.name == '签单金额') {
                    this.departmentOption.legend.selected = {
                        '签单金额': true,
                        '回款金额': false,
                        '精准客资数': false,
                        // '商机数': false
                    };
                } else if (e.name == '回款金额') {
                    this.departmentOption.legend.selected = {
                        '签单金额': false,
                        '回款金额': true,
                        '精准客资数': false,
                        // '商机数': false
                    };
                } else if (e.name == '精准客资数') {
                    this.departmentOption.legend.selected = {
                        '签单金额': false,
                        '回款金额': false,
                        '精准客资数': true,
                        // '商机数': false
                    };
                }
                // else if (e.name == '商机数') {
                //     this.departmentOption.legend.selected = {
                //         '签单金额': false,
                //         '回款金额': false,
                //         '商机客资数': false,
                //         '商机数': true
                //     };
                // }
                this.departmentParameters = this.getEnumeration(e.name);
                this.getDepartmentData();
            })

        },

        getEnumeration(val) {
            let enumeration = '';
            switch (val) {
                case '签单金额':
                    enumeration = 'SIGNED_AMOUNT'
                    break;
                case '回款金额':
                    enumeration = 'REFUND_AMOUNT'
                    break;
                case '精准客资数':
                    enumeration = 'BUSINESS_OPPORTUNITY_CUSTOMER_CAPITAL'
                    break;
                // case '商机数':
                //     enumeration = 'NUMBER_OF_OPPORTUNITIES'
                //     break;
            }
            return enumeration
        },
        toLink(path) {
            this.$router.push(path);
        },
    },
};
</script>

<style scoped>
.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0;
}

.card-container {
    margin: 10px 0;
}

.download_con {
    position: relative;
}

.download-icon {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 3;
}

.m-download-icon {
    position: absolute;
    bottom: 18px;
    right: 8px;
    z-index: 3;
}

.card-h {
    :deep(.ant-card-head) {
        padding: 10px 10px 4px 10px;
    }
}

.m-data-glance {
    :deep(.ant-card-body) {
        padding: 10px;
    }

    :deep(.ant-card-head) {
        padding: 6px;
    }
}
</style>
<style lang="scss">
.rangStyle {
    .ant-picker-panels {
        display: inline-flex;
        flex-wrap: nowrap;
        direction: ltr;
        flex-direction: column;
    }
}
</style>